<template>
	<div>
		<div class="container">
			<div class="handle-box">
				<el-input size="small" v-model="phone" clearable maxlength="12" placeholder="号码" style="width: 180px;"
					class="handle-input mr10"></el-input>
				<el-button size="small" type="primary" :class="[theme]" icon="el-icon-search"
					@click="dxtQuerys">搜索</el-button>
			</div>
			<div class="table-box" v-if="dxtDet">
				<div class="user-info-box" style="width: 500px;">
					<div class="user-info-tit">账户信息</div>
					<div class="info-box">
						<div class="info-name">认证手机号：</div>
						<div class="info">{{ dxtDet.billId }}</div>
					</div>
				</div>
				<div class="user-info-box" style="width: 500px;">
					<div class="info-box">
						<div class="info-name">状态：</div>
						<div class="info">{{ dxtDet.status }}</div>
					</div>
				</div>
				<div class="user-info-box" style="width: 500px;">
					<div class="info-box">
						<div class="info-name">审核备注：</div>
						<div class="info">{{ dxtDet.auditMsg }}</div>
					</div>
				</div>
				<div class="user-info-box" style="width: 500px;">
					<div class="info-box">
						<div class="info-name">审核时间：</div>
						<div class="info">{{ dxtDet.auditDate }}</div>
					</div>
				</div>
				<div class="user-info-box" style="width: 500px;">
					<div class="info-box">
						<div class="info-name">提交时间：</div>
						<div class="info">{{ dxtDet.lastCommitDate }}</div>
					</div>
				</div>
			</div>


		</div>
	</div>
</template>

<script>
	import { auditResult } from '../api/dijia.js';
	export default {
		name: 'accountReview',
		data() {
			return {
				theme: localStorage.getItem('theme'),
				phone: '',
				batchquery: {
					pageIndex: 1,
					pageSize: 10,
					userId: localStorage.getItem('user')
				},
				dxtDet: '',
				activeName: 'single',
				uploadData: {
					userId: localStorage.getItem('user')
				},
				addVisible: false,
				tableData: [],
				pageTotal: 0,
				token: { Authorization: localStorage.getItem('token') },
				path: process.env.BASE_URL,
				dosageVO: ''
			};
		},

		created() {
			if (this.phone) {
				this.dxtQuerys();
			}
		},

		methods: {
			tableRowClassName({ rowIndex }) {
				if ((rowIndex + 1) % 2 === 0) {
					return 'oddRow';
				}
				return 'evenRow';
			},

			dxtQuerys() {
				this.dxtDet = '';
				let data = {
					phone: this.phone
				};
				auditResult(data).then(res => {
					if (res.code == 200) {
						this.dxtDet = res.data.content;
					} else {
						this.$message.error(res.message);
					}
				});
			},

		}
	};
</script>

<style scoped>
	.handle-box {
		margin-bottom: 20px;
	}

	/* a:hover {
	background: #66b1ff;
} */
	.handle-select {
		width: 240px;
	}

	.table {
		width: 100%;
		font-size: 12px;
	}

	.red {
		color: #f56c6c;
	}

	.mr10 {
		margin-right: 10px;
	}

	.mr100 {
		position: absolute;
		right: 100px;
	}

	.myRight {
		position: absolute;
		right: 10px;
		bottom: 10px;
	}

	.handle-input {
		width: 200px;
		display: inline-block;
	}

	.recharge-box .el-radio__input {
		display: none;
	}

	.recharge-box .el-radio.is-bordered {
		padding: 8px 14px 0 5px;
		border-radius: 4px;
		border: 1px solid #dcdfe6;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		height: 30px;
	}

	.table-box {
		width: 1400px;
		border: 1px solid #ebeef5;
		display: flex;
		flex-direction: column;
		margin-bottom: 40px;
	}

	.user-info-box {
		/* border: 1px solid #ebeef5; */

		width: 600px;
	}

	.user-info-tit {
		background: #fafafa;
		color: #000;
		font-weight: 600;
		text-align: center;
		height: 34px;
		line-height: 34px;
		border-right: 1px solid #ebeef5;
		border-bottom: 1px solid #ebeef5;
	}

	.info-box {
		display: flex;
		flex-direction: row;
		height: 34px;
		line-height: 34px;
		border-bottom: 1px solid #ebeef5;
		border-right: 1px solid #ebeef5;
	}

	.info-name {
		background: #fafafa;
		border-right: 1px solid #ebeef5;
		width: 120px;
		padding-left: 10px;
		font-weight: 600;
	}

	.user-info-box .info-box .info {
		padding-left: 10px;
		color: #226003;
	}

	.down {
		border: 1px solid #dcdfe6;
		background: #4f7afd;
		color: #ffffff;
		transition: 0.1s;
		font-weight: 500;
		padding: 6px 12px;
		font-size: 12px;
		border-radius: 4px;
		display: inline-block;
	}
</style>