<template>
	<div>
		<div class="container">
			<div class="handle-box">
				<el-input size="small" v-model="query.phoneNumber" clearable placeholder="手机号" class="handle-input mr10"
					style="width: 180px;"></el-input>
				<el-button size="small" type="primary" :class="[theme]" icon="el-icon-search"
					@click="handleSearch">搜索</el-button>
				<el-button size="small" type="primary" :class="[theme]" @click="showForm">添加报备号码</el-button>
				<el-button size="small" type="primary" :class="[theme]" @click="showForm2">手动添加报备号码</el-button>
				<a :href="`${path}reportNumber.xlsx`" class="down mr10" download="报备号码模板.xlsx" :class="[theme]">
					<i class="el-icon-download"></i>
					下载模板
				</a>
				<el-button size="small" type="primary" :class="[theme]" icon="el-icon-delete  el-icon--right"
					@click="batchDel">批量删除</el-button>
				<el-button size="small" type="primary" :class="[theme]" icon="el-icon-upload2  el-icon--right"
					@click="exportData">导出</el-button>
			</div>
			<el-row :gutter="20" type="flex" class="row-bg">
				<el-col :span="24">
					<!-- .slice(0, 33) -->
					<el-table :data="tableData" border class="table" ref="multipleTable"
						header-cell-class-name="table-header" :row-style="{ height: '20px' }"
						:cell-style="{ padding: '6px' }" :header-cell-style="{ 'text-align': 'center' }"
						@selection-change="handleSelectionChange" :row-class-name="tableRowClassName">
						<el-table-column type="selection" width="55" align="center"></el-table-column>
						<el-table-column prop="phoneNumber" label="电话号码" align="center"></el-table-column>
						<el-table-column prop="cardType" label="品牌" align="center"></el-table-column>

						<el-table-column label="操作" align="center">
							<template #default="scope">
								<el-button size="small" type="text" icon="el-icon-delete" style="color: #f56c6c;"
									@click="handleDelete(scope.$index, scope.row.id)">删除</el-button>
							</template>
						</el-table-column>
					</el-table>
				</el-col>
			</el-row>
			<div class="pagination">
				<el-pagination background layout="total, sizes, prev, pager, next, jumper"
					:current-page="query.pageIndex" :page-sizes="[30, 100, 200, 500]" :page-size="query.pageSize"
					:total="pageTotal" @size-change="handleSizeChange"
					@current-change="handlePageChange"></el-pagination>
			</div>
		</div>

		<div class="report-number">
			<el-dialog title="添加报备号码" v-model="upload" width="650px" :close-on-click-modal="false">
				<el-upload ref="upload" action="/api/blackList/addReportNumber" :headers="token" :limit="1"
					:on-success="uploadSuccess" :on-preview="handlePreview" :on-remove="handleRemove"
					:auto-upload="false" :data="uploadData">
					<el-button size="small" type="primary" :class="[theme]">选择文件</el-button>
				</el-upload>
				<el-button size="small" style="margin: 10px 0 0 80%;" type="primary" :class="[theme]"
					v-loading.fullscreen.lock="loading" @click="submitUpload">确定上传</el-button>
			</el-dialog>
		</div>
		<div class="report-number">
			<el-dialog title="批量删除" v-model="deleteVisible" width="650px" :close-on-click-modal="false">
				<el-form :model="deleteForm" ref="deleteForm" label-width="120px">
					<el-form-item label="删除号码">
						<el-input size="small" type="textarea" :rows="5" placeholder="一行一个号码,一次最多添加100个号码"
							v-model="deleteForm.phones"></el-input>
					</el-form-item>
					<el-form-item style="display: flex;flex-direction: row;justify-content: end;">
						<el-button size="small" @click="deleteVisibleClose">取消</el-button>
						<el-button size="small" type="primary" :class="[theme]" v-loading.fullscreen.lock="loading"
							@click="batchDel2">确定删除</el-button>
					</el-form-item>
				</el-form>
			</el-dialog>
		</div>
		<el-dialog title="手动添加" v-model="addVisible" width="650px" :close-on-click-modal="false">
			<el-form :model="addForm" ref="addForm" label-width="120px">
				<el-form-item label="品牌" prop="cardType">
					<el-select size="small" v-model="addForm.cardType" filterable placeholder="请选择品牌"
						style="width: 100%;">
						<el-option v-for="item in cardTypeList" :key="item.label" :label="item.value"
							:value="item.value"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="添加号码">
					<el-input size="small" type="textarea" :rows="5" placeholder="一行一个号码,一次最多添加100个号码"
						v-model="addForm.phones"></el-input>
				</el-form-item>
				<el-form-item style="display: flex;flex-direction: row;justify-content: end;">
					<el-button size="small" @click="addVisibleClose">取消</el-button>
					<el-button size="small" type="primary" :class="[theme]" @click="getAddReport">确定上传</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
	</div>
</template>

<script>
	import { getReportNumberList, deleteReportNumber, downloadReportNumber } from '../api/reportNumber.js';
	import { addReport } from '../api/companyIndex.js';
	import { getCardType } from '../api/cardType.js';
	export default {
		name: 'reportNumber',
		data() {
			return {
				theme: localStorage.getItem('theme'),
				deleteVisible: false,
				deleteForm: {
					phones: ''
				},
				query: {
					phoneNumber: '',
					pageIndex: 1,
					pageSize: 30
				},
				tableData: [],
				delList: [],
				pageTotal: 0,
				form: {
					phoneNumber: ''
				},
				loading: false,
				upload: false,
				path: process.env.BASE_URL,
				multipleSelection: [],
				addForm: {
					phones: '',
					cardType: ''
				},
				addVisible: false,
				cardTypeList: []
			};
		},
		created() {
			this.getData();
		},
		methods: {
			tableRowClassName({ rowIndex }) {
				if ((rowIndex + 1) % 2 === 0) {
					return 'oddRow';
				}
				return 'evenRow';
			},
			getData() {
				let data = {
					phoneNumber: this.query.phoneNumber.replaceAll(' ', ''),
					pageSize: this.query.pageSize,
					pageIndex: this.query.pageIndex
				};
				getReportNumberList(data).then(res => {
					if (res.code == 200) {
						this.tableData = res.data.list;
						this.pageTotal = res.data.total;
					} else {
						this.$message.error(res.message);
					}
				});
			},
			showForm() {
				this.upload = true;
			},

			// 触发搜索按钮
			handleSearch() {
				this.query.pageIndex = 1;
				this.getData();
			},
			exportData() {
				this.loading = true;
				downloadReportNumber().then(res => {
					const blob = new Blob([res], {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
					});
					let downloadElement = document.createElement('a');
					let href = window.URL.createObjectURL(blob);
					downloadElement.href = href;
					let fileName = '报备号码' + '.xlsx';
					downloadElement.download = fileName;
					document.body.appendChild(downloadElement);
					downloadElement.click();
					document.body.removeChild(downloadElement);
					window.URL.revokeObjectURL(href);
					this.loading = false;
				});
			},
			/* 批量删除 */
			batchDel() {
				console.log(this.multipleSelection, 'this.multipleSelection');
				let str = '';
				this.delList = this.delList.concat(this.multipleSelection);
				for (let i = 0; i < this.multipleSelection.length; i++) {
					str += this.multipleSelection[i].value + ',';
				}
				if (str === '') {
					this.deleteVisible = true;
				} else {
					let param = {
						ids: str
					};
					// 二次确认删除
					this.$confirm('确定要删除吗？', '提示', {
							type: 'warning'
						})
						.then(() => {
							deleteReportNumber(param).then(res => {
								if (res.code == 200) {
									this.$message.success('批量删除成功');
									this.getData();
								} else {
									this.$message.error(res.message);
								}
							});
						})
						.catch(() => {});
				}

				this.multipleSelection = [];
			},
			batchDel2() {
				let param = {
					phones: this.deleteForm.phones
				};
				deleteReportNumber(param).then(res => {
					if (res.code == 200) {
						this.deleteVisible = false;
						this.deleteForm.phones = '';
						this.$message.success('批量删除成功');
						this.getData();
					} else {
						this.$message.error(res.message);
					}
				});
			},
			deleteVisibleClose() {
				this.deleteVisible = false;
				this.deleteForm.phones = '';
			},
			// 多选操作
			handleSelectionChange(val) {
				this.multipleSelection = val;
			},
			// 删除操作
			handleDelete(index, id) {
				// 二次确认删除
				this.$confirm('确定要删除吗？', '提示', {
						type: 'warning'
					})
					.then(() => {
						let data = {
							ids: id
						};
						deleteReportNumber(data).then(res => {
							if (res.code == 200) {
								this.$message.success('删除成功');
								this.getData();
							} else {
								this.$message.error(res.message);
							}
						});
					})
					.catch(() => {});
			},
			submitUpload() {
				if (this.$refs.upload.uploadFiles[0]) {
					this.loading = true;
					this.$refs.upload.submit();
				} else {
					this.$message.error('请选择上传文件');
				}
			},
			uploadSuccess(success) {
				setTimeout(() => {
					this.loading = false;
					this.$refs.upload.clearFiles();
				}, 2000);
				if (success.code == 200) {
					this.$message.success('上传成功');
					this.upload = false;
				} else {
					// this.open(success.message);
					this.$message.error(success.message);
				}
			},
			// 分页导航
			handlePageChange(val) {
				this.query.pageIndex = val;
				this.getData();
			},
			handleSizeChange(val) {
				this.query.pageSize = val;
				this.getData();
			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
			},

			showForm2() {
				this.addVisible = true;
				this.addForm.cardType = '';
				this.addForm.phones = '';
				getCardType('').then(res => {
					this.cardTypeList = res.data;
				});
			},
			getAddReport() {
				let data = {
					phones: this.addForm.phones,
					cardType: this.addForm.cardType
				};
				console.log(data, 'data');
				addReport(data).then(res => {
					if (res.code == 200) {
						this.$message.success(res.data);
						this.addVisible = false;
						this.getData();
					} else {
						this.$message.error(res.message);
					}
				});
			},
			addVisibleClose() {
				this.addVisible = false;
			}
		}
	};
</script>

<style scoped>
	.handle-box {
		margin-bottom: 50px;
	}

	/* a:hover{background: #66b1ff} */
	.handle-select {
		width: 240px;
	}

	.table {
		width: 100%;
		font-size: 12px;
	}

	.red {
		color: #f56c6c;
	}

	.mr10 {
		margin-right: 10px;
	}

	.mr100 {
		position: absolute;
		right: 100px;
	}

	.myRight {
		position: absolute;
		right: 10px;
		bottom: 10px;
	}

	.handle-input {
		width: 200px;
		display: inline-block;
	}

	.down {
		border: 1px solid #dcdfe6;
		background: #4f7afd;
		color: #ffffff;
		transition: 0.1s;
		font-weight: 500;
		padding: 6px 16px;
		font-size: 12px;
		border-radius: 4px;
		margin-left: 10px;

		display: inline-block;
	}
</style>

<style>
	.report-number .el-upload {
		width: 100% !important;
		display: flex !important;
		flex-direction: column !important;
		align-items: center !important;
		justify-content: center !important;
	}

	.report-number .btn {
		background-color: #4f7afd;
		margin-top: 20px;
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		border: none;
		margin-left: 80%;
	}
</style>